<template>
  <div>
    <PageLayout class="container">
      <template v-if="!isTaskSelected" #header>
        <h1>
          {{ $t('tasks.tasksPage.title') }}
        </h1>
      </template>

      <template v-if="!isTaskSelected" #tabs>
        <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
      </template>

      <div v-for="{ component, index } in tabsContent" :key="index">
        <component :is="component" v-if="index === activeTab" />
      </div>
    </PageLayout>
  </div>
</template>

<script type="text/javascript">
import { getCurrentInstance, ref, computed, watch, onMounted } from 'vue';
import { PageLayout, Tabs } from '@/modules/core';
import OpenTasks from './OpenTasks.vue';
import ClosedTasks from './ClosedTasks.vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { debounce } from 'lodash';
import { isNil } from 'ramda';

const TAB = {
  OPEN_TASKS: 0,
  CLOSED_TASKS: 1,
};

const SECTION = {
  open: { activeTab: TAB.OPEN_TASKS, name: 'open' },
  closed: { activeTab: TAB.CLOSED_TASKS, name: 'closed' },
};

export default {
  components: {
    PageLayout,
    Tabs,
    OpenTasks,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { $i18n } = getCurrentInstance().proxy;
    const activeTab = ref();

    onMounted(() => {
      const tab = route.query.section;
      setActiveTab(tab);
    });

    const setActiveTab = (tab) => {
      if (tab) {
        const _activeTab = SECTION[tab].activeTab;
        activeTab.value = _activeTab;
      } else {
        // default open tasks tab
        activeTab.value = TAB.OPEN_TASKS;
      }
    };

    const isTaskSelected = computed(() => {
      const queryTasks = route.query.task;
      return !!queryTasks;
    });

    watch(
      activeTab,
      debounce((newActiveTab, currentActiveTab) => {
        let query = { ...route.query };
        const isFirstLoad = isNil(currentActiveTab);
        if (currentActiveTab !== newActiveTab && !isFirstLoad)
          // clear query on section change
          query = {};

        if (activeTab.value === TAB.OPEN_TASKS) router.push({ query: { ...query, section: SECTION.open.name } });
        if (activeTab.value === TAB.CLOSED_TASKS) router.push({ query: { ...query, section: SECTION.closed.name } });
      }),
      { immediate: true }
    );

    watch(route, () => {
      const tab = route.query.section;
      setActiveTab(tab);
    });

    const tabs = computed(() => [
      { text: $i18n.t('tasks.tasksPage.tabs.openTasks') },
      {
        text: $i18n.t('tasks.tasksPage.tabs.closedTasks'),
      },
    ]);

    const tabsContent = computed(() => {
      const components = [OpenTasks, ClosedTasks];
      return components.map((component, index) => ({ component, index }));
    });

    return { tabs, tabsContent, activeTab, isTaskSelected };
  },
};
</script>

<style scoped>
.container {
  min-width: 1100px;
  max-width: unset;
}
</style>
